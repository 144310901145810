/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(22, 28%, 21%);
  --clr-primary-2: hsl(22, 28%, 29%);
  --clr-primary-3: hsl(22, 28%, 37%);
  --clr-primary-4: hsl(22, 28%, 45%);
  /* primary/main color */
  --clr-primary-5: hsl(22, 31%, 52%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(22, 31%, 60%);
  --clr-primary-7: hsl(22, 31%, 67%);
  --clr-primary-8: hsl(20, 31%, 74%);
  --clr-primary-9: hsl(22, 31%, 81%);
  --clr-primary-10: hsl(22, 31%, 88%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/


*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
	font-family: "Trajan";
	src: local("Trajan"), url("./assets/Fonts/00186-UTM-Trajan-Pro-Bold.ttf") format ("truetype");
	font-weight: bold
}
@font-face {
	font-family: "SVN-Gotham Bold";
	src: local("SVN-Gotham Bold"), url("./assets/Fonts/SVN-Gotham Bold.ttf") format ("truetype");
	font-weight: bold;
}
@font-face {
	font-family: "SVN-Gotham Light";
	src: local("SVN-Gotham Light"), url("./assets/Fonts/SVN-Gotham Light.ttf") format ("truetype");
	font-weight: light;
}

@font-face {
	font-family: "Poppins-regular";
	src: local("Poppins-regular"), url("./assets/Fonts/font-poppins/Poppins-Regular.otf") format("opentype");
	
}
@font-face {
	font-family: "Poppins-medium";
	src: local("Poppins-medium"), url("./assets/Fonts/font-poppins/Poppins-Medium.otf") format("opentype");
	
}
@font-face {
	font-family: "Poppins-bold";
	src: local("Poppins-bold"), url("./assets/Fonts/font-poppins/Poppins-Bold.otf") format("opentype");
	
}

body {
  font-family: 'Poppins', sans-serif;
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}
.btn-show{
  position: absolute;
  left: 4%;
  bottom: 8.2%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  /* background-color: red; */
  /* background: white; */
  /* border-radius: 50%; */

}
.btn-home{
  position: absolute;
  right: 3%;
  bottom: 21.2%;
  
  width: 63px;
  height: 63px;
  cursor: pointer;
  /* animation: scale 1s linear; */
  /* animation-iteration-count: infinite;
  animation-delay: -3s; */

}
.btn-home:hover{
  opacity: 0.8;
}
.btn-home>svg{
 height: 100%;
 width: 100%;


}

.btn-contact{
  position: absolute;
  right: 3%;
  bottom: 12.2%;
  width: 63px;
  height: 63px;
  /* transform-origin: 50% 50%; */
 
  animation: scale 2s linear;
  transform-origin: center;
  animation-iteration-count: infinite;
  animation-delay: -5s;


  

  
  cursor: pointer;
}
.btn-contact>svg{
 width: 100%;
 height: 100%;
 

}
.btn-contact:hover{
  opacity: 0.8;
}


@media screen and (max-width: 630px) {
 
  .btn-contact{
    bottom: 20.2%!important;
  
 }
 .btn-home{
  bottom: 28.2%!important
 }


}
.app{
  width: 100vw;
  height: 100vh;
  position: relative;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
hr {
  border: none;
  border-top: 1px solid var(--clr-grey-8);
}
h1,
h2,
h3,
h4,
h5 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-3);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section {
  padding: 5rem 0;
}
.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}
.text-center {
  text-align: center;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
@keyframes scaleIn {
  from {
      transform: scale(.5, .5);
      opacity: .1;
  }
  to {
      transform: scale(20.5, 20.5);
      opacity: 0;
  }
}
@keyframes Nhay {
  0% {
      transform: translateY(0);
      
  }
  50% {
      transform: translateY(20px);
     
  }
  100%{
    transform: translateY(0px);
      
  }
}
@keyframes show {
  from {
     
      opacity: 0;
      transform: translateX(-100%);
  }
  to {
    
      opacity: 1;
      transform: translateX(0);

  }
}
@keyframes hide {
  from {
     
      opacity: 1;
      /* transform: translateX(0); */
  }
  to {
    
      opacity: 0;
      /* transform: translateX(-100%); */

  }
}

@keyframes scale {
  0% {
     
      transform: rotate(0deg)
   
  }
 
  100% {
    
    transform: rotate(360deg)
  

  }
}

.loading {
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
  margin-top: 10rem;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: var(--clr-primary-5);
  animation: spinner 0.6s linear infinite;
}

.btn {
  text-transform: uppercase;
  background: var(--clr-primary-5);
  color: var(--clr-primary-10);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 400;
  transition: var(--transition);
  font-size: 0.875rem;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius);
  border-color: transparent;
}
.btn:hover {
  color: var(--clr-primary-1);
  background: var(--clr-primary-7);
}
.title {
  text-align: center;
}
.title .underline {
  width: 6rem;
  height: 0.25rem;
  background: #49a6e9;
  background: var(--clr-primary-5);
  margin-left: auto;
  margin-right: auto;
}
.page-100 {
  min-height: calc(100vh - 10rem);
  padding: 5rem 0;
}
.page {
  min-height: calc(100vh - (20vh + 10rem));
}
.texterror{
  background: linear-gradient(
        274.38deg,
        #ce8f31 0%,
        #f6e493 50%,
        #efd45a 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
}
.backerror:hover{
  opacity: 0.8;

}
